import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO />
    <section className="text-gray-700 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-1/3 md:w-1/3 w-5/6 mb-10 md:mb-0">
          <Img
            fluid={data.hero.childImageSharp.fluid}
            alt=""
            className="lg:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            loading="eager"
            durationFadeIn={50}
          />
          <Img
            fluid={data.sub_hero.childImageSharp.fluid}
            alt=""
            className="lg:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            loading="eager"
            durationFadeIn={50}
          />
        </div>
        <div className="lg:flex-grow md:w-2/3 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            日本の文化を英語で伝えたい、調べたい、説明したい、紹介したい全ての人へ
          </h1>
          <p className="mb-8 leading-relaxed">
            34年間にわたり日本の文化を研究した米国人ウォルター・Ｊ・ハンフリー氏の日本の文化、習慣、民俗、芸術、神話、民話に関する英文エッセイの対訳版です。
            <br />
            <br />
            茶道、華道、宗教、文楽などの日本文化に関するものから、漆器、和紙、型染め、根付け、彫り物、七宝焼、筒描きなどの民芸、サメ、泣砂、琴姫にまつわる民話、出雲神話、そして醤油、米、酒などの食物、結婚式、音楽会、旅、教育、国際交流などの日常生活に関するものなど、幅広い分野で約400点のエッセイを収録しました。
            <br />
            <br />
            住んでいながら知らなかった日本のこと、米国人が魅了された素晴らしい日本の文化を再発見してください。英語の対訳文章が日本語で収められていますので英語の学習にも最適です。
            <br />
            <br />
            日本の文化を英語で伝えたい、調べたい、説明したい、紹介したい全ての人に贈ります。
          </p>
          <div className="flex justify-center flex-col md:flex-row">
            <div>
              <Link
                to="/essays/"
                className="w-full inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-xl"
              >
                エッセイ集を読む
              </Link>
            </div>
            <div>
              <Link
                to="/keywords/"
                className="w-full mt-5 md:mt-0 md:ml-4 inline-flex text-gray-700 bg-gray-300 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-xl"
              >
                キーワード集を読む
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "image_title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sub_hero: file(relativePath: { eq: "image_e.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
